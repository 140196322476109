body,
* {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #011f3b;
  font-size: 13px;
}

.logo-loader {
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.animation-loader-pulse {
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: pulse;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.ql-toolbar {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.ql-container {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ql-editor {
  height: 120px !important;
}
